body {
  font-family: $fontFamilyRoboto;
}

.closed {
  .sidebar {
    background: transparent;
    display: 0;
  }

  .main-panel {
    width: 100%;
  }
}

.p-selectbutton {
  .p-button {
    &.p-highlight {
      background: #d62c3d !important;
      border-color: #000 !important;
      color: #ffffff !important;
      &:hover {
        background: #d62c3d !important;
        border-color: #000 !important;
        color: #ffffff !important;
      }
      &:focus {
        box-shadow: none;
      }
    }
  }
  .p-button {
    &:not(.p-disabled):not(.p-highlight):hover {
      background: #d62c3d !important;
      border-color: #000 !important;
      color: #fff !important;
    }
  }
}

input {
  &.ng-dirty {
    &.ng-invalid > .p-inputtext {
      border-color: #e24c4c;
    }
  }
}

.p-checkbox {
  &:not(.p-checkbox-disabled) {
    .p-checkbox-box {
      &.p-highlight {
        border-color: #d62c3d !important;
        background: #d62c3d !important;
        color: #ffffff !important;
        &:hover {
          border-color: #d62c3d !important;
          background: #d62c3d !important;
          color: #ffffff !important;
        }
      }
    }
  }

  .p-disabled {
    border-color: #d62c3d !important;
    background: #d62c3d !important;
    color: #ffffff !important;
  }
}

.title {
  color: #292929;
  font-size: 24px;
  opacity: 0.87;
}

.title-underline {
  font-size: 24px;
  font-weight: bold;
  margin-right: 8px;
  position: relative;

  &::after {
    border-bottom: 3px solid $titleUnderline;
    content: "";
    left: 0;
    margin-top: 3px;
    position: absolute;
    top: 100%;
    width: 56%;
  }
}

.link-copied-message {
  background: $secondaryTextColor;
  border-radius: 5px;
  color: $white;
  float: right;
  padding: 7px;
  z-index: 99999;
}

.payment-failed {
  background: $btnNumBgColor;
  border-radius: 5px;
  color: $white;
  padding: 5px;
  display: inline-block;
}

.btn-1 {
  background: $btnNumBgColor;
  border-radius: 4px;
  color: $white;
  margin: 0 30px;
  padding: 8px 1px;
  text-align: center;
  width: 32px;
}

.fa-check {
  font-size: 16px;
}

.fa-pencil {
  cursor: pointer;
}

.text-after {
  position: relative;

  &::after {
    border-top: 2px dashed #d9d8d7;
    content: "";
    height: 0;
    left: 0;
    margin: 15px auto;
    position: absolute;
    right: 0;
    width: 50%;
    z-index: 1;
  }
}

.active {
  color: $btnNumBgColor;
}

.ft-wt {
  font-weight: 400;
}

.btn-2 {
  background-color: $btnNumBgInactiveColor;
  border-radius: 4px;
  color: $white;
  margin: 0 30px;
  padding: 8px 1px;
  text-align: center;
  width: 32px;
}

.text-center {
  text-align: center;
}

.img-circle {
  border: 1px solid $black;
  border-radius: 50%;
  width: 60px;
}

.img-max {
  max-height: 100%;
  max-width: 100%;
}

.anchor-color {
  color: $breadCumbActiveColor;
}

.inline {
  display: inline-block;
}

.underline {
  text-decoration: underline;
}

.mr-zero {
  margin: 0;

  &.c-name {
    margin: 10px;
  }
}

.margin-six {
  margin: 6px;
}

.padding-tb-zero {
  padding-bottom: 0;
  padding-top: 0;
}

.mr-20 {
  margin: 20px 0;
}

.ml-10 {
  margin: 0 10px;
}

.ml-20 {
  margin: 0 20px;
}

.tier {
  color: #000;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  opacity: 0.78;
  padding-left: 0;
}

.tier-details {
  font-family: Lato;
  font-size: 16px;
  opacity: 0.78;
  padding-left: 0;
}

.center-block {
  display: block;
  margin: 60px auto;
}

.mt-zero {
  margin-top: 0;
}

.mb-20 {
  margin-bottom: 20px;
}

.right-panel {
  background-color: $mainContentBgColor;
}

.right-panel-wrapper {
  padding: 25px;
}

.form-buttons {
  .ui-button {
    &:hover,
    &:focus {
      background-color: $themeBtnBgColor;
      border-color: transparent;
      outline: 0;
    }
  }
}

.theme-button-secondary {
  background: transparent;
  border: 1px solid $themeBtnBgColor;
  border-radius: 3px;
  box-shadow: 0;
  color: $themeBtnBgColor;
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-weight: 500;
  height: 36px;
  opacity: 1;
  padding: 12px 0;
  text-align: center;

  &.ui-button {
    &:hover {
      background: $themeBtnBgColor;
      border: 0;
    }
  }
}

.theme-btn {
  background-color: $themeBtnBgColor;
  border: 1px solid transparent;
  border-radius: 4px;
  color: $white;
  display: block;
  font-size: 28px;
  height: 50px;
  opacity: 1;

  &.ui-button {
    &:focus,
    &:hover {
      background: $themeBtnBgColor;
      border: 1px solid transparent;
    }
  }

  &:disabled {
    background-color: #ea929a;
  }

  
}

.p-button:enabled:hover {
  background: $themeBtnBgColor;
  color: #ffffff;
  border-color: #ea929a;
}

.delete-btn {
  height: 44px;
  margin-bottom: 20px;
  width: 276px;

  .ui-button-text {
    font-size: 20px;
  }
}

.icon-style {
  opacity: 0.6;
  position: absolute;
  right: 0;

  &.fa {
    display: block;
    font-size: 28px;
  }
}

.relative-block {
  position: relative;
}

.add-more {
  color: $breadCumbActiveColor;
  cursor: pointer;
  float: right;
}

.add-more-btn {
  color: $breadCumbActiveColor;
  font-size: 16px;
  font-weight: bold;
  height: 19px;
  margin: 40px 0;
  text-align: right;
}

.middle-section {
  background: $white;
  overflow: visible;
  padding-top: 24px;
  position: relative;
}

.pd-lr {
  padding-left: 0;
  padding-right: 0;
}

.social-icon {
  display: inline-block;
  margin-right: 10px;
  margin-top: 12px;

  img {
    margin: -5px 5px;
    width: 54px;
  }

  .left-arrow {
    background-color: $white;
    border: 1px solid #d8d8d8;
    color: #000;
    font-size: 10px;
    padding: 3px 7px;
    text-align: center;
  }

  .pd-lr {
    padding-left: 0;
    padding-right: 0;
  }
}

.para {
  color: $black;
  font-size: 14px;
  opacity: 0.6;
}

.mt-13 {
  margin: 13px 0;
}

.pl-80 {
  padding: 0 50px 0 80px;
}

.m10 {
  margin: 10px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-10 {
  margin-top: 10px;
}

.m-20 {
  margin: 20px;
}

.mt-50 {
  margin-top: 50px;
}

.align-right {
  float: right;
}

.mt-20 {
  margin: 20px 0;
}

.mtb-10 {
  margin: 10px 0;
}

.border-btm {
  border-bottom: $primaryBorder;

  &:nth-last-child(1) {
    border: 0;
  }
}

.text-and-link {
  color: #bf1e2e;
}

.next-btn {
  background-color: $formBtnBgColor;
  border: 1px solid transparent;
  border-radius: 6px;
  font-size: 20px;
  height: 44px;
  object-fit: contain;
  width: 127px;

  &:hover {
    background: $formBtnBgColor;
    border: 1px solid transparent;
    outline: 0;
  }
}

.mr-rt-15 {
  margin-right: 15px;
}

.center-wrapper {
  background: $white;
  margin: 0 auto;
  overflow: hidden;
  padding: 25px;
  position: relative;
  width: 400px;
}

.border-botttom {
  border-bottom: solid 1px #cbcbcb;
}

.text-right-align {
  text-align: right;
}

.text-no-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.full-height {
  height: 79vh;
}

.top-margin {
  margin-top: 56px;
}

.coach-inner-wrap {
  background: url("../../assets/donor-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 76vh;
  overflow: auto;
  padding: 38px 0;
}

.clearfix {
  clear: both;
  float: none;
}

.coach-layout {
  background: $white;
  margin: 0 auto;
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;

  .col-pad-right {
    padding-right: 40px;
  }
}

.social-share {
  .soc-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 13px;
    margin-top: 8px;
  }
}

.app-header {
  background: $white;
  margin: 0 auto;
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  overflow: hidden;
  padding-left: 60px;
  padding-right: 60px;

  .logo {
    padding: 0px 4px 8px 27px;
  }
}

.sub-details {
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  opacity: 0.5;
}

.success-box {
  padding: 15px;
}

.overflow-hidden {
  overflow: hidden;
}

.pad-left {
  padding-left: 33px;
}

.mr-10 {
  margin-right: 10px;
  margin-top: 10px;
}

.mrr-10 {
  margin-right: 10px;
}

.footer-margin {
  margin-bottom: 60px;
}

.back-btn {
  border-radius: 8px;
  font-size: 21px;
  font-weight: bold;
  margin: 0 auto;
  text-align: center;
}

.coach-footer {
  background: $white;
  bottom: 0;
  font-family: Lato;
  left: 0;
  padding: 10px 0;
  position: relative;
  right: 0;
  width: 100%;

  .footer {
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    padding: 0 35px;

    .copy-right {
      color: $calendarIconColor;
      font-size: 14px;
    }

    a {
      color: #292929;
      font-size: 14px;
    }
  }
}

.ui-progressbar {
  height: 20px;
}

@media screen and (max-width: 1440px) {
  .next-btn {
    width: 84px;
  }

  .social-icon {
    margin-top: 10px;
  }

  .progressbar {
    height: 13px;
    width: 91%;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .tier-details {
    font-size: 14px;
  }

  .tier {
    font-size: 15px;
  }

  .mb-center {
    display: block;
    margin: 0 auto;
  }

  .coach-layout {
    padding: 0;
  }

  .privacy-text,
  .copy-text {
    margin-bottom: 0;
    text-align: center;
  }

  .app-header {
    .logo {
      padding: 8px 0;
      text-align: center;
    }
  }
  .pad-left {
    padding-left: 10px;
   }
  .hide-col-reponsive {
    display: none;
  }
}

.loader-hidden {
  visibility: hidden;
}

.loader-page-center-overlay {
  background-color: transperent;
  bottom: 0;
  height: 70px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 88px;
  z-index: 500000;
}

.loader-wrap {
  display: block;

  .loading {
    bottom: 0;
    height: 2em;
    left: 0;
    margin: auto;
    overflow: show;
    position: fixed;
    right: 0;
    top: 0;
    width: 2em;
    z-index: 999;

    &::before {
      background-color: rgba(0, 0, 0, 0.3);
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
    }
  }
}

.donar-container {
  background: url("../../assets/donationBG1.png");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;
  padding: 0 20px;

  .donar-title {
    border-bottom: 6px solid #df1e2e;
    color: #df1e2e;
    margin: 16px 0 0;
    padding-bottom: 10px;
    text-align: center;
    text-transform: uppercase;
  }

  .tech-slideshow {
    margin: 0 auto;
    max-height: 234px;
    overflow: hidden;
    position: relative;
    transform: translate3d(0, 0, 0);

    .mover {
      border-bottom: 3px solid #df1e2e;
      color: #fff;
      padding: 5px 20px;
      transform: translate3d(0, 0, 0);
      transition: all 2s linear;

      &:nth-of-type(odd) {
        background: rgba(0, 0, 0, 0.04);
        border-bottom: 3px solid #c9c7c7;
      }

      .item-name {
        color: #000;
        font-size: 17px;
        font-weight: 600;
        margin: 0;
        opacity: 0.8;

        &.title {
          overflow: hidden;
          text-overflow: ellipsis;
          text-transform: capitalize;
          white-space: nowrap;
        }
      }
    }
  }
}

.p-progressbar {
  border-radius: 0;
  width: 91%;
  height: 30px;
}


.p-ripple {
	&.p-element {
		&.p-button {
			&.p-component {
				border-radius: 5px !important;
				color: #000;
				font-weight: 700;
				height: 45px;
				margin: 0 4px 20px;
				border: 1px solid #000;
			}
		}
	}
}

@media screen and (max-width: 767px) {
  .sm\:col-12 {
  flex: 0 0 auto;
  width: 100%;
  }
  }