.success-wrap {
  margin-top: 50px;
  padding: 50px;
  padding-bottom: 25px;

  .social-share {
    button {
      margin: 20px 0;
    }
  }
}

@media screen and (max-width: 640px) {
  .success-wrap {
    margin-top: 30px;
    padding: 0;
  }
}
