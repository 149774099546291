.campaign-wrapper {
  align-items: center;
  // background: $formBtnBgColor;
  background: $formBoxGreyBgColor;
  display: flex;
  justify-content: center;
  min-height: 305px;

  .donor-img {
    // border: 3px solid #FFF;
    border: 2px solid #000;
    border-radius: 50%;
    height: 200px;
    width: 200px;
  }

  .div-center {
    align-items: center;
    display: flex;
  }

  .text-wrap {
    margin: 0 25px;
  }

  .name {
    // color: #FFF;
    color: #000;
    display: block;
    font-family: $fontFamilyRoboto;
    font-size: 55px;
    margin-bottom: 0;
  }

  .para {
    color: #000;
    font-size: 35px;
    margin-top: 0;
    opacity: 1;
  }
}

.heading {
  color: #000;
  font-size: 49px;
  font-weight: bold;
  opacity: .8;
}

.para {
  font-size: 16px;
  line-height: 26px;
}

.prof-img {
  border: 1px solid $dropdownBorder;
  // border-radius: 30px;
  // height: 60px;
  // width: 60px;
  border-radius: 50px;
  height: 80px;
  width: 80px;
}

.pull-left {
  float: left;
  margin-right: 20px;
}

@media screen and (max-width: 1440px) {
  .heading {
    font-size: 30px;
  }
}

@media screen and (max-width: 640px) {
  .campaign-wrapper {
    min-height: 267px;
    padding: 30px;

    .div-center {
      display: block;
      text-align: center;
    }

    .text-wrap {
      display: block;
    }

    .donor-img {
      // border: 3px solid #FFF;
      border: 2px solid #000;
      border-radius: 50%;
      height: 200px;
      margin: 0 auto;
      width: 200px;
    }

    .name,
    .para {
      font-size: 20px;
    }
  }

  .heading {
    font-size: 20px;
  }
}
