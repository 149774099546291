body {
  background-color: $bodyBgColor;
  color: $BodyTextColor;
  font-family: $fontFamilyRoboto;
  font-size: $fontSize;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  margin: 0;
  padding: 0;
}

h1 {
  color: #292929;
  font-family: Roboto;
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  letter-spacing: normal;
  line-height: normal;
}

.signin-para {
  color: #292929;
  font-size: 18px;
  font-weight: bold;
  opacity: .8;
}

input {
  box-sizing: border-box;

  &.ui-inputtext, &.p-inputtext {
    border: $primaryBorder;
    border-radius: 4px;
    color: $black;
    height: 40px;
    padding: 0 10px;
    width: 100%;

    &:focus,
    .ui-state-focus {
      border-color: $InputHoverColor;
      box-shadow: 0 0 5px $InputHoverColor;
      outline: 0;
    }

    &:hover {
      border-color: $InputHoverColor;
    }
  }

  a {
    text-decoration: none;
  }
}

.ui-dropdown-panel {
  .ui-dropdown-item {
    &:not(.ui-state-highlight) {
      &:hover {
        background: $themeBtnBgColor;
      }
    }

    &.ui-state-highlight {
      background: $themeBtnBgColor;
    }
  }
}

.donation-form-style {
  &.ui-selectbutton {
    .ui-button {
      background: #FFF;
      border: 1px solid #000;
      border-radius: 5px;
      color: #000;
      font-weight: bold;
      height: 45px;
      margin: 0 5px 20px;
      padding: 10px;
      text-align: center;
      width: auto;

      &:not(.ui-state-active),
      &:not(.ui-state-disabled) {
        &:hover {
          background: $themeBtnBgColor;
          border: 1px solid transparent;
          color: #FFF;
        }
      }

      &.ui-state-active,
      &.ui-state-focus {
        background: $themeBtnBgColor;
        color: #FFF;
        opacity: 1;
      }
    }
  }
}

textarea {
  border: $primaryBorder;
  border-radius: 4px;
  box-sizing: border-box;
  color: #000;
  font-family: $fontFamilyLato;
  font-size: 16px;
  height: 170px;
  overflow: auto;
  padding: 9px;
  resize: none;
  width: 100%;
  // padding: 0 10px;

  &::placeholder {
    color: $black;
    font-family: $fontFamilyLato;
    font-size: 18px;
    font-weight: 400;
    opacity: .4;
  }
}

.label {
  color: $black;
  font-family: $fontFamilyLato;
  font-size: 16px;
  font-weight: bold;
  opacity: .8;
}
.label-charges{
    color: $black;
    font-family: $fontFamilyLato;
    font-size: 14px;
    opacity: .8;
}

button {
  background-color: $themeBtnBgColor;
  color: $white;
  display: block;
  font-size: 28px;
  // height: 50px;
  opacity: 1;
  width: auto;
}

.form-errors {
  color: $themeBtnBgColor;
}

.ui-tabview {
  overflow: hidden;
}

.ui-dropdown {
  background: $white;
  border: 0;
  border-radius: 4px;
  color: #000;

  &:not(.ui-state-disabled) {
    &:hover {
      background: $white;
      border-radius: 4px;
    }
  }

  .ui-dropdown-label {
    border: solid 1px #A8AAAC;
    border-radius: 4px;
    color: #000;
    font-weight: 500;
    height: 40px;
    opacity: .7;
    padding: 10px;
    width: 100%;
  }

  .ui-dropdown-trigger {
    background: $white;
    border: solid 1px #A8AAAC;
    border-left: 0;
    border-radius: 0;

    .ui-dropdown-trigger-icon {
      color: $dropdownBorder;
      margin-top: -5px;

      &.fa {
        margin-left: -.125em;
        margin-top: .8em;
        width: 20px;
      }
    }
  }
}

.form-buttons {
  .ui-state-disabled,
  .ui-widget {
    &:disabled {
      cursor: not-allowed;
      opacity: .4;
    }
  }
}

.ui-chkbox {
  .ui-chkbox-box {
    border: 1px solid #000;

    &:not(.ui-state-active) {
      border: 1px solid #000;

      &:focus,
      &:hover {
        background: $white;
        border: 1px solid #000;
      }
    }

    &.ui-state-active {
      background: $formBtnBgColor;
      border: 0;
    }

    &:not(.ui-state-disabled) {
      &:not(.ui-state-active),
      .ui-state-focus {
        background: $white;
      }
    }
  }
}

.ui-calendar {
  .ui-button-icon-only {
    .fa {
      color: #CCC;
    }
  }

  .ui-calendar-button {
    background: $white;

    &:hover {
      background: $themeBtnBgColor;
      border: 0;
      outline: 0;
    }
  }

  &.ui-calendar-w-btn {
    input {
      border: 1px solid $borderRightColor;
      border-right: 0;
      color: $black;
      opacity: .8;
      padding: 0 20px;
    }
  }
}

@media screen and (max-width: 1440px) {
  .responsive-container {
    background: #FFF;
  }

  .donation-form-style {
    &.ui-selectbutton {
      .ui-button {
        padding: 10px;
        text-align: center;
        width: auto;

        .ui-button-text {
          padding: 2px;
        }
      }
    }
  }

  .other-amount {
    .label {
      font-size: 13px;
    }
  }
}

@media screen and (max-width: 767px) {
  .donation-form-style {
    &.ui-selectbutton {
      .ui-button {
        padding: 10px;
        width: auto;
      }
    }
  }


  input {
    &.ui-inputtext {
      font-size: 16px;
    }
  }

  textarea {
    font-size: 16px;
  }

  .other-amount {
    .label {
      font-size: 13px;
    }
  }
}

@media screen and (max-width: 340px) {
  .donation-form-style {
    &.ui-selectbutton {
      .ui-button {
        margin: 12px;
        padding: 11px;
        width: auto;
      }
    }
  }
}



