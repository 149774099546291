//Body related variables
$bodyBgColor: #FFF;
$bodyColor: #333;
$mainContentBgColor: #EEE;
$themeBtnBgColor: #D62C3D;
$titleColor: #999;

// Font related variables
$fontFamilyRoboto: 'Roboto', Lato;
$fontFamilyLato: 'Lato', Roboto;
$fontSize12: 1.2rem;
$fontSize13: 1.3rem;
$fontSize14: 1.4rem;
$fontSize16: 1.6rem;
$fontSize23: 2.3rem;
$fontWeight400: 400;
$fontWeight500: 500;
$fontWeight600: 600;
$fontWeight700: 700;
$fontWeight300: 300;
$fontColor: #FFF;
$InputHoverColor: #DDD;

$menuColor: #7B7E7E;
$menuBtnColor: #909293;
$menuBtnAnchor: #7B7E7E;

//title color
$analyticParaColor: #A4A2B5;
$analyticTitleColor: #34475B;

//checkbox


$incompleteProgressBar: #D62C3D;


//Breadcumb
$breadCumbActiveColor: #2B90FF;
$breadCumbColor: #7B7E7E;

//progressbar
$progGreenColor: #5F9A21;
$imgBgColor:  #DBE2D9;

//profile
$profileItemColor: #505050;

//underline
$titleUnderline: #FA5E6D;

// common
$primaryBorder: solid 1px #A8AAAC;
$btnNumBgColor: #DF1E2E;
$btnNumBgInactiveColor: #B7B7B7;
$border: solid 1px #445561;
$borderBottomColor: #F0F0F0;
$borderRightColor: #D8D8D8;
$borderDashedColor: #D9D8D7;
$buttonDefaultTextColor: #FFF;
$greyBgColor: #F7F7F7;
$buttonDefaultColor: #4A90E2;
$borderDefaultColor: #445561;
$secondaryTextColor: #11AC60;
$dropdownBgColor: #EFEFEF;
$columnThDefaultColor: #A8AAAC;
$secondaryBgColor: #FCFCFC;
$tableTRBorder: solid 1px #E0E0E0;
$dropdownTriggerColor: #9C9C9C;
$dropdownBorder: #4A4A4A;



@mixin bgimg-class {
  background-image: url('/assets/images/icons.png');
  background-repeat: no-repeat;
  display: inline-block;
}

@mixin btn-class {
  background-color: $buttonDefaultColor;
  border: 1px solid $buttonDefaultColor;
  color: $buttonDefaultTextColor;
  height: 29px;
  line-height: 1;
  padding: .5px;
}

@mixin subheader {
  color: $borderDefaultColor;
  font-size: 1.8rem;
  font-weight: $fontWeight600;
  text-transform: uppercase;
}

@mixin subtext {
  color: $borderDefaultColor;
  font-size: $fontSize12;
  font-weight: $fontWeight300;
  margin: 10px 0;
}

@mixin table-td-data {
  color: $buttonDefaultColor;
  font-size: $fontSize12;
}



// Colors 
$white: #FFF;

//$defaultThemeColor: #EC1D25;
$defaultThemeColor: #249E94;
$secondaryThemeColor: #3D4246;
$inputGroupTextColor: #000;


$mainContentBgColor: #F2F5F7;
$topBarBgColor: $white;
$topBarItemHoverBgColor: #F1F1F1;
$BodyTextColor: #454545;

$linkColor: #666161;
$topBarTextColor: #303030;

$sidebarBgColor: $secondaryThemeColor;
$sidebarMenuTextColor: #A4A2B5;
$sideBarHoverBgColor: #D43443;
$sidebarMenuHoverTextColor: $white;
$sidebarIconColor: #B0BEC5;


$signInBgColor: #009688;
$headerFontSize: 24px;
$headerSplitBarColor: #CBD1DC;
$headerUserName: #101010;

$buttonDefaultTextColor: $white;
$buttonDefaultBgColor: $defaultThemeColor;
$negativeButtonColor: $secondaryThemeColor;
$buttonHoverColor: #D21911;
$negativeButtonHoverColor: #24282A;

$pageHeaderBgColor: transparent;
$panelDivBgColor: $white;

// Forms

$formFieldTopBottomMargin: 8px;
$formFieldLeftRightMargin: 0;
$inputFieldsBgColor: $white;
$inputFieldBorderColor: #E1E3E7;
$inputTextHeight: 30px;
$readOnlyFieldColor: #DEDEDE;
$activeBoxTextColor: #636363;
$inputBorderColor: #E1E3E7;
$formBtnBgColor: #BF1E2E;
$formUploadBgColor: #F5F5F5;
$formBoxGreyBgColor: #eaeaea;

// Pring NG 

$fontFamily:  'Montserrat', sans-serif;
$fontSize: 14px;
$borderRadius: 2px;
$disabledOpacity: .35;
$calendarIconColor: #3D3D3D;
$dropdownBgColor: #1A1A1A;
$dropdownBorderColor: #C5C0CB;
$selectButtonBorderColor: #EEE;

// Header
$headerBorderWidth: 1px;
$headerBorderColor: $white;
$headerBgColor: $white;
$headerTextColor: #444;
$headerFontWeight: bold;
$headerIconTextColor: #444;

// Content
$contentBorderWidth: 1px;
$contentBorderColor: #DDD;
$contentBgColor: $white;
$contentTextColor: #444;

// Default State
$stateDefaultBorderWidth: 1px;
$stateDefaultBorderColor: #DDD;
$stateDefaultBgColor: #F6F6F6;
$stateDefaultTextColor: #0073EA;

// Active State
$stateActiveBorderColor: #DDD;
$stateActiveBgColor: $white;
$stateActiveTextColor: $white;

// Highlight State
$stateHighlightBorderColor: #9158D9;
$stateHighlightBgColor: #9158D9;
$stateHighlightTextColor: $white;

// Focus State
$stateFocusBorderColor: #0073EA;
$stateFocusBgColor: #0073EA;
$stateFocusTextColor: $white;

// Error State
$stateErrorBorderColor: #DDD;
$stateErrorBgColor: $white;
$stateErrorTextColor: #FF0084;

// Anchor Color
$anchorColor: #0073EA;

// Hover State
$stateHoverBorderColor: #0073EA;
$stateHoverBgColor: #0073EA;
$stateHoverTextColor: $white;

// Forms
$inputBgColor: $white;
$inputTextColor: #444;
$invalidInputBorderColor: #FF0084;

// Glow messages
$growlMessageErrorColor: #F44336;
$growlMessageSuccessColor: #4CAF50;

// Grid 
$gridHeaderTextColor: #1E1E1E;
$gridHeaderBgColor: #EDEDED;
$gridHeaderBorderColor: $topBarBgColor;
$gridBtnColorDanger: #D9534F;
$gridBtnColorDangerFocus: #C9302C;
$gridBtnColorSuccess: #5CB85C;
$gridBtnColorSuccessFocus: #449D44;
$loaderBgColor: transperent;
$actionButtonDeactivate: #1E1E1E;
$gridSelectedRowHover: #D9DBDD;
$gridFileHoverColor: #4CAF50;

$color_log_cabin_approx: #222;
$color_boulder_40_approx: rgba(120, 120, 120, .4);
$color_fuscous_gray_40_approx: rgba(80, 80, 80, .4);
$color_shark_40_approx: rgba(40, 40, 40, .4);
$black_40: rgba(0, 0, 0, .4);
$color_japanese_laurel_approx: #006400;
$color_red_berry_approx: #8B0000;
$color_surf_crest_approx: #CEC;
$color_quill_gray_approx: #D3D3D3;
$color_pumice_approx: #C7C7C7;
$color_danube_approx: #5B9BD5;
$color_silver_approx: #BEBEBE;
$color_black_haze_approx: #F6F6F6;
$color_gallery_approx: #F0F0F0;
$white_50: rgba(255, 255, 255, .5);
$black: #000;
$gray: #808080;

$gridOddRowColor: $white;
$gridRowSelectedColor: #EEEAEA;
$gridSelectorHighliterColor: #EDEDED;
$paginationTextColor: #3E3E3E;


// Checkbox
$checkboxBackgroundColor: $white;
$checkboxBorderColor: #EDEDED;
$checkboxActiveBorderColor: #E1E3E7;
$checkboxCheckColor: $defaultThemeColor;

// Table
$tableHeaderText: rgba(30, 30, 30, .5);

// google map realate color
$mapPickup: #4DA03A;
$mapDrop: #EC1D25;

$destinationBox: #E9E9EC;


