.profile-wrapper {
  background: $white;
  margin-bottom: 20px;
  padding: 25px;

  .incomplete {
    .p-progressbar-determinate {
      .p-progressbar-value {
        background: $incompleteProgressBar;
      }
    }
  }

  .title {
    margin: 0;
  }

  .donor-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
    margin-top: 0;
  }

  .prof-img {
    object-fit: contain;
    width: 100%;
  }

  .pd-lr {
    padding-left: 0;
    padding-right: 0;
  }

  .item-value {
    color: $black;
    font-family: $fontFamilyLato;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: .8px;
    margin: 4px 0;
  }

  .item-name {
    color: $profileItemColor;
    font-family: $fontFamilyLato;
    font-size: 16px;
    margin: 0;
    opacity: .8;

    &.title {
      font-size: 17px;
      margin-bottom: 10px;
    }
  }

  .anchor-color {
    color: $breadCumbActiveColor;
  }

  .p-progressbar {
    height: 30px;
    width: 91%;
  }

  .p-progressbar-label {
    font-size: 0;
  }

  .p-progressbar-value {
    background: $progGreenColor;
  }

  .profile-info {
    overflow: hidden;
    padding: 20px;
  }

  .centrer {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .profile-wrapper {
    .theme-button-secondary {
      height: 36px;
    }
  }

  .review-start {
    color: $themeBtnBgColor;
    font-family: Lato;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    letter-spacing: normal;
    line-height: normal;
  }

  .social-share {
    button {
      background: transparent;
      border: 0;
      cursor: pointer;
      display: inline-block;
      margin: 0 10px;
      margin-left: 0;
      outline: 0;
    }
  }
}

@media screen and (max-width: 1440px) {
  .title {
    font-size: 24px;
  }

  .responsive-container {
    background: $white;
    position: relative;
  }

  .img-container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;

    .centerer {
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }

    img {
      display: inline-block;
      max-height: 100%;
      max-width: 100%;
      vertical-align: middle;
    }
  }

  .profile-wrapper {
    .donor-title {
      font-size: 21px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .theme-button-secondary {
      .ui-button-text {
        overflow: hidden;
        padding: .25em 1em;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .profile-wrapper {
    .item-name {
      font-size: 12px;

      &.title {
        font-size: 13px;
      }
    }

    .img-parent-wrap {
      background: $white;
      padding: 15px;
    }

    .img-container {
      position: relative;

      .prof-img {
        width: auto;
      }
    }

    .label,.label-charges {
      font-size: 13px;
    }
  
  }
}

@media screen and (max-width: 480px) {
  .profile-wrapper {
    padding: 0;

    img {
      margin: 0 auto;
      width: 60px;
    }

    .centrer {
      display: block;
    }

    .social-share {
      button {
        display: inline-block;
        margin: 0 auto;
        width: 50%;
      }
    }

    .item-value {
      font-size: 15px;
    }

    .label,.label-charges {
      font-size: 13px;
    }
  }
}
